import React, { useState, useEffect, useContext } from "react";

import Layout from "../../layouts/DefaultLayout";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";

import AppCard from "./AppCard";

import styled from "styled-components";

import { ConfigContext } from "../../provider/ConfigProvider";

import isEmpty from "lodash/isEmpty";

import { IApp } from "./type";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Tools from "../tools";
import {
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsalAuthentication
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

const HomeContainer = styled.div`
  min-height: 425px;
`;

const Home = () => {
  const [appCards, setAppCards] = useState<IApp[]>([]);
  const { envConfig } = useContext(ConfigContext);
  const [config, initiateConfig] = envConfig;

  const isAuthenticated = useIsAuthenticated();

  const { login, result, error } = useMsalAuthentication(
    InteractionType.Redirect
  );

  useEffect(() => {
    initiateConfig();
  }, []);

  useEffect(() => {
    if (!isEmpty(config)) {
      fetch(`/apps/${config.ENV}/apps.json`)
        .then((response) => response.json())
        .then((data) => setAppCards(data.apps));
    }
  }, [config]);

  useEffect(() => {
    if (result !== null) {
      login();
    }
  }, []);

  const page = (
    <BrowserRouter>
      <Layout>
        <Switch>
          <Route exact path="/">
            <Container>
              <HomeContainer>
                <Row className="mb-5 justify-content-center">
                  {appCards ? (
                    appCards.map((card: any) => (
                      <Col xs={12} md={4} key={card.title}>
                        <AppCard
                          title={card.title}
                          owner={card.owner}
                          tooltip={card.tooltip}
                          tooltipPlacement={card.tooltipPlacement}
                          buttonLabel={card.buttonLabel}
                          url={card.url}
                        />
                      </Col>
                    ))
                  ) : (
                    <Spinner variant="primary" animation="border" />
                  )}
                </Row>
              </HomeContainer>
            </Container>
          </Route>
          <Route path="/tools">
            <Tools />
          </Route>
        </Switch>
      </Layout>
    </BrowserRouter>
  );

  return isAuthenticated ? (
    page
  ) : (
    <UnauthenticatedTemplate>
      <p>No users are signed in!</p>
    </UnauthenticatedTemplate>
  );
};

export default Home;

