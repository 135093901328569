import React, { useEffect } from "react";
import Home from "./pages/Home";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faUser, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { ConfigProvider } from "./provider/ConfigProvider";

import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useIsAuthenticated
} from "@azure/msal-react";
import { config } from "./clientApp/configAdal";
import { PublicClientApplication } from "@azure/msal-browser";
import { InteractionType } from "@azure/msal-browser";

library.add(faUser);
library.add(faSignOutAlt);

function ErrorComponent({ error }: MsalAuthenticationResult) {
  return <p>An Error Occurred: {error}</p>;
}

function LoadingComponent() {
  return <p>Authentication in progress...</p>;
}

function App() {
  const pcaAlert = new PublicClientApplication(config);

  return (
    <MsalProvider instance={pcaAlert}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        errorComponent={ErrorComponent}
        loadingComponent={LoadingComponent}
      >
        <ConfigProvider>
          <Home />
        </ConfigProvider>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;

