import React from "react";

import Header from "./Header";
import Footer from "./Footer";

const DefaultLayout = (props: any) => {
  return (
    <div>
      <Header {...props} />
      <div
        id="main-container"
        className="container"
        style={{ height: "100%", maxHeight: "550px" }}
      >
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
