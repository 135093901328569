import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";

const StyledFooter = styled.footer`
  position: fixed;
  bottom: 0;
  width: 100%;
`;
StyledFooter.displayName = "StyledFooter";

const Footer = () => {
  return (
    <StyledFooter id="footer-container">
      <div id="bluefooter-container">
        <Container id="bluefooter">
          <div id="be-think-innovate">be think innovate</div>
          <div className="footer-divider"></div>
          <Row>
            <Col xs={3}>
              <div>
                <address>
                  <div className="firstline">
                    <strong>GRUNDFOS Holding A/S</strong>
                  </div>
                  <span>
                    <span>Poul Due Jensens Vej 7</span>
                    <br />
                    <span>DK-8850</span>
                    <span>Bjerringbro</span>,<span>Denmark</span>
                    <br />
                    Tel.:&nbsp;
                    <span>+45&nbsp;87501400</span>| Fax&nbsp;
                    <span>+45&nbsp;87501402</span>
                    <br />
                    CVR no. 31 85 83 56
                  </span>
                </address>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </StyledFooter>
  );
};

export default Footer;
