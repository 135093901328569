import React, { useState } from "react";
import styled from "styled-components";
import { acquireAccessToken } from "../../../clientApp/authConfig";
import { useMsal } from "@azure/msal-react";

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
FlexColumnDiv.displayName = "FlexColumnDiv";

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
ToolsContainer.displayName = "ToolsContainer";

const MySpan = styled.span`
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
  border: 1px solid rgba(1, 1, 1, 0.1);
  background-color: #ebebe4;
`;
MySpan.displayName = "MySpan";

type TokenGetterProps = {
  tokenType: "alert" | "asset";
};

const TokenGetter = ({ tokenType }: TokenGetterProps) => {
  const { instance } = useMsal();
  const [token, setToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onClickGetAlertToken = () => {
    setIsLoading(true);
    acquireAccessToken(instance, tokenType === "alert").then((value) => {
      setIsLoading(false);
      setToken(value);
    });
  };

  return (
    <FlexColumnDiv>
      <h2>{tokenType === "alert" ? "Alert" : "Asset"}</h2>
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyItems: "center",
            alignItems: "center"
          }}
        >
          <button
            type="button"
            style={{ width: "100px" }}
            disabled={isLoading}
            onClick={onClickGetAlertToken}
          >
            Get Token
          </button>
        </div>
        <MySpan role="textbox" contentEditable>
          {token}
        </MySpan>
      </div>
    </FlexColumnDiv>
  );
};

export default TokenGetter;
