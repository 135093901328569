import { IPublicClientApplication } from "@azure/msal-browser";
import { ConfigChecker } from "./envConfig";

const hostname = window && window.location && window.location.hostname;
const checker = new ConfigChecker(hostname);
const envConfig = checker.getEnvConfig();

export const apiUrl = envConfig.getAssetEndpoint();

export const notificationApiUrl = envConfig.getNotificationEndpoint();

export const alertingApiUrl = envConfig.getAlertingEndpoint();

export const teamsApiUrl = envConfig.getAlertTeamsEndpoint();

export const policyApiUrl = envConfig.getPolicyEndpoint();

const azureAd = {
  instance: "https://login.microsoftonline.com",
  domain: "grundfos.onmicrosoft.com",
  clientIdAlert: "bbc6d768-b483-4e23-b015-516b7c0eddf6",
  clientId: "963e8e70-fcf9-44e3-8806-8ed078a10def",
  tenantId: "dabd5d90-87c2-44c9-93cd-783e03236e40",
  resourceId: "def2667f-a58b-4165-9ad1-e6db545697d8",
  resourceIdAlert: "d23ea94a-8ad2-456e-b4d8-3a502e42aa61"
};

export const config = {
  auth: {
    authority: `${azureAd.instance}/${azureAd.tenantId}`,
    clientId: azureAd.clientIdAlert,
    redirectUri: window.location.origin
  },
  cache: {
    cacheLocation: "sessionStorage" as "sessionStorage",
    storeAuthStateInCookie: true
  }
};

const authenticationParameters = {
  scopes: [`${azureAd.resourceId}/user_impersonation`]
};

const authenticationParametersAlert = {
  scopes: [
    `https://grundfos.onmicrosoft.com/gic-alertservice/user_impersonation`
  ]
};

export const adalApiFetchWithToken = async (
  token: string,
  fetch: any,
  url: string,
  options: any
) => {
  const o = options || {};
  if (!o.headers) {
    o.headers = {};
  }

  o.headers.Authorization = `Bearer ${token}`;

  return fetch(url, o);
};

export const adalApiFetch = async (
  fetch: any,
  url: string,
  options: any,
  msalInstance: IPublicClientApplication,
  isAlert = false
) => {
  return acquireAccessToken(msalInstance, isAlert).then((token) => {
    const o = options || {};
    if (!o.headers) {
      o.headers = {};
    }

    o.headers.Authorization = `Bearer ${token}`;
    return fetch(url, o);
  });
};

export const acquireAccessToken = async (
  msalInstance: IPublicClientApplication,
  isAlert = false
) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
    throw new Error("user not logged in");
  }
  const request = {
    // scopes: ["User.Read"],
    scopes: isAlert
      ? authenticationParametersAlert.scopes
      : authenticationParameters.scopes,
    account: activeAccount || accounts[0]
  };

  const authResult = await msalInstance.acquireTokenSilent(request);

  return authResult.accessToken;
};
