import React, { useState } from "react";
import styled from "styled-components";
import { acquireAccessToken } from "../../clientApp/authConfig";
import { useMsal } from "@azure/msal-react";
import AlertToken from "./alert-token/alert-token.component";

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
`;
FlexColumnDiv.displayName = "FlexColumnDiv";

const ToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
ToolsContainer.displayName = "ToolsContainer";

const Tools = () => {
  const { instance } = useMsal();
  const [alertToken, setAlertToken] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onClickGetAlertToken = () => {
    setIsLoading(true);
    acquireAccessToken(instance, true).then((value) => {
      setIsLoading(false);
      setAlertToken(value);
    });
  };

  return (
    <ToolsContainer>
      <FlexColumnDiv>
        <h1>Token</h1>
        <AlertToken tokenType="alert" />
        <br />
        <AlertToken tokenType="asset" />
      </FlexColumnDiv>

      <hr />
      <FlexColumnDiv>
        <h1>Raise/Clear Alert</h1>
      </FlexColumnDiv>
    </ToolsContainer>
  );
};

export default Tools;
