export class EnvConfig {
  private _hostname: string;
  private _assetApiUrl: string;
  private _alertApiUrl: string;
  private _policyApiUrl: string;

  get hostname() {
    return this._hostname;
  }
  set hostname(value) {
    this._hostname = value;
  }

  get assetApiUrl() {
    return this._assetApiUrl;
  }
  set assetApiUrl(value) {
    this._assetApiUrl = value;
  }

  get alertApiUrl() {
    return this._alertApiUrl;
  }
  set alertApiUrl(value) {
    this._alertApiUrl = value;
  }

  get policyApiUrl() {
    return this._policyApiUrl;
  }
  set policyApiUrl(value) {
    this._policyApiUrl = value;
  }

  constructor(
    hostname = "",
    assetApiUrl = "",
    alertApiUrl = "",
    policyApiUrl = ""
  ) {
    this._hostname = hostname;
    this._assetApiUrl = assetApiUrl;
    this._alertApiUrl = alertApiUrl;
    this._policyApiUrl = policyApiUrl;
  }

  protected readonly getValue = (envUrl = "") => {
    return envUrl;
  };

  /**
   * Default to D
   * @method getAlertingEndpoint
   */
  public getAlertingEndpoint() {
    return this.getValue(process.env.REACT_APP_K_D_ALERTING_API_URL_V2);
  }

  /**
   * Default to D
   * @method getAssetEndpoint
   */
  public getAssetEndpoint() {
    return this.getValue(process.env.REACT_APP_K_D_API_URL);
  }

  /**
   * Default to D
   * @method getPolicyEndpoint
   */
  public getPolicyEndpoint() {
    return this.getValue(process.env.REACT_APP_K_D_POLICY_API_URL);
  }

  public getAlertTeamsEndpoint() {
    return `${this.getAlertingEndpoint()}/teams`;
  }

  public getNotificationEndpoint() {
    return `${this.getAlertingEndpoint()}/notification`;
  }
}

export class DevConfig extends EnvConfig {
  constructor(hostname = "") {
    super(
      hostname,
      process.env.REACT_APP_K_D_API_URL,
      process.env.REACT_APP_K_D_API_URL,
      process.env.REACT_APP_K_D_POLICY_API_URL
    );
  }

  public getAlertingEndpoint() {
    let val = super.getAlertingEndpoint();

    if (this.hostname === process.env.REACT_APP_K_D_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_D_ALERTING_API_URL_V2);
    }

    return val;
  }

  public getAssetEndpoint() {
    let val = super.getAssetEndpoint();

    if (this.hostname === process.env.REACT_APP_K_D_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_D_API_URL);
    }

    return val;
  }

  public getPolicyEndpoint() {
    let val = super.getPolicyEndpoint();

    if (this.hostname === process.env.REACT_APP_K_D_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_D_POLICY_API_URL);
    }

    return val;
  }
}

export class TestConfig extends EnvConfig {
  constructor(hostname = "") {
    super(
      hostname,
      process.env.REACT_APP_K_T_API_URL,
      process.env.REACT_APP_K_T_API_URL,
      process.env.REACT_APP_K_T_POLICY_API_URL
    );
  }

  public getAlertingEndpoint() {
    let val = super.getAlertingEndpoint();

    if (this.hostname === process.env.REACT_APP_K_T_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_T_ALERTING_API_URL_V2);
    }

    return val;
  }

  public getAssetEndpoint() {
    let val = super.getAssetEndpoint();

    if (this.hostname === process.env.REACT_APP_K_T_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_T_API_URL);
    }

    return val;
  }

  public getPolicyEndpoint() {
    let val = super.getPolicyEndpoint();

    if (this.hostname === process.env.REACT_APP_K_T_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_T_POLICY_API_URL);
    }

    return val;
  }
}

export class QaConfig extends EnvConfig {
  constructor(hostname = "") {
    super(
      hostname,
      process.env.REACT_APP_K_Q_API_URL,
      process.env.REACT_APP_K_Q_API_URL,
      process.env.REACT_APP_K_Q_POLICY_API_URL
    );
  }

  public getAlertingEndpoint() {
    let val = super.getAlertingEndpoint();

    if (this.hostname === process.env.REACT_APP_K_Q_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_Q_ALERTING_API_URL_V2);
    }

    return val;
  }

  public getAssetEndpoint() {
    let val = super.getAssetEndpoint();

    if (this.hostname === process.env.REACT_APP_K_Q_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_Q_API_URL);
    }

    return val;
  }

  public getPolicyEndpoint() {
    let val = super.getPolicyEndpoint();

    if (this.hostname === process.env.REACT_APP_K_Q_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_Q_POLICY_API_URL);
    }

    return val;
  }
}

export class ProdConfig extends EnvConfig {
  constructor(hostname = "") {
    super(
      hostname,
      process.env.REACT_APP_K_P_API_URL,
      process.env.REACT_APP_K_P_API_URL,
      process.env.REACT_APP_K_P_POLICY_API_URL
    );
  }

  public getAlertingEndpoint() {
    let val = super.getAlertingEndpoint();

    if (this.hostname === process.env.REACT_APP_K_P_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_P_ALERTING_API_URL_V2);
    }

    if (this.hostname === process.env.REACT_APP_K_P_HOSTNAME_FINAL) {
      val = this.getValue(process.env.REACT_APP_K_P_ALERTING_API_URL_FINAL);
    }

    return val;
  }

  public getAssetEndpoint() {
    let val = super.getAssetEndpoint();

    if (
      this.hostname === process.env.REACT_APP_K_P_HOSTNAME_V2 ||
      this.hostname === process.env.REACT_APP_K_P_HOSTNAME_FINAL
    ) {
      val = this.getValue(process.env.REACT_APP_K_P_API_URL);
    }

    return val;
  }

  public getPolicyEndpoint() {
    let val = super.getPolicyEndpoint();

    if (this.hostname === process.env.REACT_APP_K_P_HOSTNAME_V2) {
      val = this.getValue(process.env.REACT_APP_K_P_POLICY_API_URL);
    }

    return val;
  }
}

export class ConfigChecker {
  private _hostname: string;

  get hostname() {
    return this._hostname;
  }
  set hostname(value) {
    this._hostname = value;
  }

  constructor(hostname = "") {
    this._hostname = hostname;
  }

  public isDev() {
    return this._hostname === process.env.REACT_APP_K_D_HOSTNAME_V2;
  }

  public isTest() {
    return this._hostname === process.env.REACT_APP_K_T_HOSTNAME_V2;
  }

  public isQa() {
    return this._hostname === process.env.REACT_APP_K_Q_HOSTNAME_V2;
  }

  public isProd() {
    return (
      this._hostname === process.env.REACT_APP_K_P_HOSTNAME_V2 ||
      this._hostname === process.env.REACT_APP_K_P_HOSTNAME_FINAL
    );
  }

  public getEnvConfig() {
    let config: EnvConfig = new DevConfig(this.hostname);

    if (this.isTest()) {
      config = new TestConfig(this.hostname);
    }
    if (this.isQa()) {
      config = new QaConfig(this.hostname);
    }
    if (this.isProd()) {
      config = new ProdConfig(this.hostname);
    }

    return config;
  }
}
